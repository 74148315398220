import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function Index() {

  return (
    <Layout>
<div className="relative bg-gray-900 h-screen flex justify-center items-center">
    <a href="/" className="absolute top-16 left-16">
        <svg className="h-20 w-20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
    </a>
  <div className="relative px-8 sm:px-12">
    <div className="text-lg max-w-prose mx-auto">
      <h1>
        <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-white sm:text-4xl">Impressum – Legal Notice</span>
      </h1>
    </div>
    <div className="mt-12 prose-indigo prose-lg text-white mx-auto">
    <p>Anturec Pharmaceuticals GmbH <br></br>
    represented by the managing directors Erasmios Bizimis and Prof. Dr. med. W. Berdel
    </p>
    <p>Börsenstraße 2-4<br></br>
    60313 Frankfurt am Main
    </p>
    <p>Tel: +49 251 83 56225 <br></br>
    E-Mail: hello@anturec.com
    </p>
    <p>Register Court: Frankfurt am Main Local Court, HRB 118388
    </p>
     </div>
  </div>
</div>

    </Layout>
  )
}

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logotr.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
}
`